/* eslint-disable @typescript-eslint/no-explicit-any */
import { PUP_DISPLAY_NAME } from '../config'
import { type Trello } from '../types/trello'

export function getShowAuthorization (
  t: Trello.PowerUp.IFrame,
): any {
  return t.modal({
    title: `${PUP_DISPLAY_NAME}`,
    url: './show-authorization.html',
    height: 400
  })
}
