import { Trello } from "./types/trello"

const PUP_DISPLAY_NAME = "Quickbooks Time Tracking"
const PUP_ID = "8"
const VAL_ERROR_BORDER = "border-amber-800"
const QUERY_LIMIT = 100
const NOT_FOUND_MESSAGE = 'not found*'

const CAPABILITY_PROPS = {
  baseUrl: window.location.href.replace(/\/$/, ""),
  logo: {
    dark: "/static/logo.png",
    light: "/static/logo.png"
  },
  authorizeIcon: {
    dark: "/static/authorize-icon.svg",
    light: "/static/authorize-icon.svg"
  },
  expandIcon: {
    dark: "/static/expand-icon.svg",
    light: "/static/expand-icon.svg"
  },
  calendarIcon: {
    dark: "/static/calendar-icon.svg",
    light: "/static/calendar-icon.svg"
  },
  hideIcon: {
    dark: "/static/hide-icon.svg",
    light: "/static/hide-icon.svg"
  },
  plusIcon: {
    dark: "/static/plus-icon.svg",
    light: "/static/plus-icon.svg"
  }
}

const HELP_ACTION = {
  icon: "https://trello.bluedashersoftware.com/static/help.svg",
  alt: "help",
  position: "right" as "right" | "left",
  callback: (t: Trello.PowerUp.IFrame) => {
    return t.popup({
      title: "Help Center",
      url: "./help.html",
      height: 300
    })
  }
}

const INITIAL_ROW_COUNT = 30

export {
  PUP_DISPLAY_NAME,
  PUP_ID,
  CAPABILITY_PROPS,
  HELP_ACTION,
  INITIAL_ROW_COUNT,
  VAL_ERROR_BORDER,
  QUERY_LIMIT,
  NOT_FOUND_MESSAGE
}
