import { apiClient } from "../../api/api"
import {
  SavedPupData,
  TimeActivityForm,
  TimeActivityWithTrelloCard
} from "../../types/power-up"
import { Trello } from "../../types/trello"
import { getTimeDiffInMinutes } from "./time-helpers"

const launchQuickbooksAuth = async (t: Trello.PowerUp.IFrame) => {
  const authUrl = await apiClient.getQuickbooksAuthUrl(t)
  // open a new window with the auth url
  window.open(authUrl, "_blank")
}


export const getDefaultTimeActivity = (
  currentCardId?: string,
  savedPupData?: SavedPupData | undefined
): TimeActivityWithTrelloCard => {
  return {
    txnDate: new Date(),
    employeeRef: savedPupData
      ? {
          name: "",
          value: savedPupData.id
        }
      : null,
    trelloCard: currentCardId
      ? {
          name: "",
          value: currentCardId
        }
      : null,
    itemRef: null,
    billableStatus: "NotBillable",
    description: "",
    startTime: "",
    endTime: "",
    syncToken: "",
    hourlyRate: 0,
    hours: 0,
    minutes: 0
  }
}

export const getTimeActivityForm = (
  timeActivity: TimeActivityWithTrelloCard,
  enterTimes: boolean
): TimeActivityForm => {
  const form: Partial<TimeActivityForm> = {
    // doesn't need validation. always required
    txnDate: {
      record: timeActivity.txnDate,
      hasValidationError: false,
      required: true
    },
    //doesn't need validation...never required
    description: {
      record: timeActivity.description,
      hasValidationError: false,
      required: false
    },
    // doesn't need validation. always required
    // will be calculated based on enterTimes before calling the function
    startTime: {
      record: timeActivity.startTime,
      hasValidationError:
        enterTimes &&
        (timeActivity.startTime === undefined ||
          Boolean(
            timeActivity.endTime &&
              getTimeDiffInMinutes(timeActivity.startTime, timeActivity.endTime) < 0
          )),
      required: enterTimes
    },
    endTime: {
      record: timeActivity.endTime,
      hasValidationError:
        enterTimes &&
        (timeActivity.endTime === undefined ||
          Boolean(
            timeActivity.startTime &&
              getTimeDiffInMinutes(timeActivity.startTime, timeActivity.endTime) < 0
          )),
      required: enterTimes
    },
    // this is going to have separate validation I think
    hours: {
      record: timeActivity.hours,
      hasValidationError: false,
      required: !enterTimes
    },
    minutes: {
      record: timeActivity.minutes,
      hasValidationError: false,
      required: !enterTimes
    },
    // checkbox...no validation needed
    billableStatus: {
      record: timeActivity.billableStatus,
      hasValidationError: false,
      required: true
    },
    // no validation required
    syncToken: {
      record: timeActivity.syncToken,
      hasValidationError: false,
      required: false,
      hidden: true
    },
    //shouldn't ever have validation error because it defaults to zero
    hourlyRate: {
      record: timeActivity.hourlyRate,
      hasValidationError: false,
      required:
        timeActivity.billableStatus === "Billable" ||
        timeActivity.billableStatus === "HasBeenBilled"
    },
    customerRef: {
      record: timeActivity?.customerRef,
      hasValidationError:
        (timeActivity.billableStatus === "Billable" ||
          timeActivity.billableStatus === "HasBeenBilled") &&
        !timeActivity?.customerRef,
      required:
        timeActivity.billableStatus === "Billable" ||
        timeActivity.billableStatus === "HasBeenBilled"
    },
    itemRef: {
      record: timeActivity.itemRef,
      hasValidationError: false,
      required: false
    },
    trelloCard: {
      record: timeActivity?.trelloCard,
      hasValidationError: false,
      required: false
    }
  }

  if (timeActivity?.id) {
    form.id = {
      record: timeActivity.id,
      hasValidationError: false,
      required: false
    }
  }

  if (timeActivity?.employeeRef) {
    form.employeeRef = {
      record: timeActivity.employeeRef,
      hasValidationError: !timeActivity.employeeRef,
      required: true //always required
    }
  } else if (timeActivity?.vendorRef) {
    form.vendorRef = {
      record: timeActivity.vendorRef,
      hasValidationError: !timeActivity.vendorRef,
      required: true //always required
    }
  } else {
    form.employeeRef = {
      record: null,
      hasValidationError: true,
      required: true
    }
  }

  return form as TimeActivityForm
}

const getSavedPupData = async (
  t: Trello.PowerUp.IFrame
): Promise<SavedPupData | undefined> => {
  const data = await t.get("member", "private", "savedPupData")
  return data ? JSON.parse(data) : undefined
}

const setSavedPupData = (t: Trello.PowerUp.IFrame, data: SavedPupData) => {
  t.remove("member", "private", "savedPupData") // this is an unstable hack, and is only needed so that t.render fires. If we saved more data we could remove this line
  t.set("member", "private", "savedPupData", JSON.stringify(data))
}

const forceRender = (t: Trello.PowerUp.IFrame) => {
  t.remove("member", "private", "render")
  t.set("member", "private", "render", "1")
}

const getShowRecentTimes = async (t: Trello.PowerUp.IFrame) => {
  const data = await t.get("board", "private", "showRecentTimes")
  return data !== "false"
}

const toggleRecentTimes = (t: Trello.PowerUp.IFrame) => {
  return getShowRecentTimes(t).then((data) => {
    t.set(
      "board",
      "private",
      "showRecentTimes",
      data ? "false" : "true"
    )
  })
}

export {
  launchQuickbooksAuth,
  getSavedPupData,
  setSavedPupData,
  forceRender,
  toggleRecentTimes,
  getShowRecentTimes
}
