/* eslint-disable @typescript-eslint/no-explicit-any */
import { PUP_DISPLAY_NAME } from "../config"
import { Trello } from "../types/trello"
import { getShowRecentTimes } from "./helpers/helpers"

export async function getCardBackSection(
  t: Trello.PowerUp.IFrame,
  props: any
): Promise<Trello.PowerUp.CardBackSection | undefined> {
  const showCardBackSection = await getShowRecentTimes(t)

  if (!showCardBackSection) {
    return undefined
  }

  return {
    title: PUP_DISPLAY_NAME,
    icon: props.baseUrl + props.logo.dark,
    content: {
      type: "iframe",
      height: 300,
      url: t.signUrl("./card-back-section.html")
    },
    action: {
      text: "Add time",
      position: "right" as "right" | "left",
      callback: (t: Trello.PowerUp.IFrame) => {
        t.popup({
          title: "Add time",
          url: t.signUrl("./card-button.html"),
          height: 700
        })
      }
    }
  }
}
