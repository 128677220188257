/* eslint-disable @typescript-eslint/no-explicit-any */

import { clearDasherToken, clearTrelloToken } from '@dasher-react/components'
import { type Trello } from '../types/trello'
import { PUP_ID } from '../config'
import { apiClient } from '../api/api'

export async function  getRemoveData (
  t: Trello.PowerUp.IFrame,
): Promise<void> {
  await apiClient.revokeQuickbooksAuth(t)
  clearTrelloToken(t, PUP_ID)
  clearDasherToken(t)
}
