import { type Trello } from "../types/trello"
import { getShowRecentTimes, toggleRecentTimes } from "../card-back-section/helpers/helpers"
import { HELP_ACTION } from "../config"

export async function getCardButton(
  t: Trello.PowerUp.IFrame,
  props: any
): Promise<Trello.PowerUp.CardButton[]> {

  const showRecentTimes = await getShowRecentTimes(t)

  return [
    {
      icon: props.baseUrl + props.calendarIcon.light,
      text: "Add time",
      callback: (t: Trello.PowerUp.IFrame) => {
        return t.popup({
          title: "Add time",
          url: t.signUrl("./card-button.html"),
          height: 700,
        })
      }
    },
    {
      icon: props.baseUrl + (showRecentTimes ? props.hideIcon.dark : props.plusIcon.dark),
      text: showRecentTimes ? "Hide times" : "Show times",
      callback: (t: Trello.PowerUp.IFrame) => {
        return toggleRecentTimes(t) 
      }
    },
    {
      icon: props.baseUrl + props.expandIcon.dark,
      text: "Expand times",
      callback: (t: Trello.PowerUp.IFrame) => {
        return t.modal({
          url: t.signUrl("./report-wrapper.html"),
          fullscreen: true,
          title: "QuickBooks Time Activities",
          height: 600,
          actions: [
            HELP_ACTION
          ]
        })
      }
    },

  ]
}
