import { type Trello } from "./types/trello"
import { CAPABILITY_PROPS, PUP_DISPLAY_NAME } from "./config"
import { getOnEnable } from "./on-enable/capability"
import { getShowAuthorization } from "./show-authorization/capability"
import { getAuthorizationStatus } from "./authorization-status/capability"
import { getShowSettings } from "./show-settings/capability"
import { getRemoveData } from "./remove-data/capability"
import { getCardButton } from "./card-button/capability"
import { getCardBackSection } from "./card-back-section/capability"

window.TrelloPowerUp.initialize(
  {
    "on-enable": (t: Trello.PowerUp.IFrame) => getOnEnable(t),
    "show-settings": async (t: Trello.PowerUp.IFrame) => await getShowSettings(t),
    'show-authorization': (t: Trello.PowerUp.IFrame) =>
    getShowAuthorization(t),
    'authorization-status': async (t: Trello.PowerUp.IFrame) => {
      return await getAuthorizationStatus(t)
    },
    'remove-data': async (t: Trello.PowerUp.IFrame) => await getRemoveData(t),
    "card-buttons": (t: Trello.PowerUp.IFrame) =>
    getCardButton(t, CAPABILITY_PROPS),
    'card-back-section': async (t: Trello.PowerUp.IFrame) =>
    await getCardBackSection(t, CAPABILITY_PROPS)
    
  },
  {
    appKey: process.env.POWERUP_APP_KEY,
    appName: PUP_DISPLAY_NAME
  }
)
