import { PUP_ID } from "../config"
import { Trello } from "../types/trello"
import { getTrelloToken, validateTrelloToken } from "@dasher-react/components"

export async function getAuthorizationStatus (t: Trello.PowerUp.IFrame) {
  const token = await getTrelloToken(t as Trello.PowerUp.IFrame, PUP_ID)
  const tokenIsValid = validateTrelloToken(token)

  return { authorized: tokenIsValid }
}
